
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import EventTypeFilter from '@/modules/events/components/events-filters/event-type-filter.vue';
import CountryFilter from '@/modules/events/components/events-filters/country-filter.vue';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';

@Component({
    components: {
        EventTypeFilter,
        CountryFilter,
        CustomSwitch,
    },
})
export default class EventsActions extends Vue {
    @Inject(UserServiceS)
    private userService!: UserService;

    get showIgnored() {
        return this.$route.query.showIgnored === 'true';
    }

    set showIgnored(value: boolean) {
        if (value) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    showIgnored: String(value),
                },
            });
            return;
        }

        const query = { ...this.$route.query };
        delete query.showIgnored;

        this.$router.push({ query });
    }

    get createEventRoute() {
        if (this.userService.isCarUser) {
            return '/events-manager/new-event';
        }

        const routeBranch = this.$route.name!.split('.')[0];

        return {
            name: `${routeBranch}.events-manager.new`,
        };
    }
}
