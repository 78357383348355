
import { Inject } from 'inversify-props';
import {
    Component, Vue,
} from 'vue-property-decorator';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import EventsSimpleCalendar from '@/modules/events/components/events-simple-calendar.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

@Component({
    components: { EventsSimpleCalendar },
})

export default class CalendarsGroup extends Vue {
    @Inject(EventsManagerServiceS)
    private eventsManagerService!: EventsManagerService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    get currentMonth(): Month {
        return this.documentFiltersService.storeState.settings.month;
    }

    get currentYear(): Year {
        return this.documentFiltersService.storeState.settings.year;
    }

    get nextMonth(): Month {
        return this.documentFiltersService.nextMonthAndYear.month;
    }

    get nextYear(): Year {
        return this.documentFiltersService.nextMonthAndYear.year;
    }

    get prevMonth(): Month {
        return this.documentFiltersService.previousMonthAndYear.month;
    }

    get prevYear(): Year {
        return this.documentFiltersService.previousMonthAndYear.year;
    }
}
